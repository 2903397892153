import React from "react"
import { graphql } from "gatsby"

// import { Disqus, CommentCount } from 'gatsby-plugin-disqus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faComments, faExclamationTriangle, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import PostCard from "../components/postCard"

import Layout from "../components/layout"
import SEO from "../components/seo"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    // const disqusConfig = {
    //   url: this.props.location.href,
    //   identifier: post.id,
    //   title: post.frontmatter.title,
    // }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.frontmatter.description || post.excerpt} />

        <article className="post-content with-image">
          <header className="post-content-header">
            <h1 className="post-content-title">{post.frontmatter.title}</h1>

            {post.frontmatter.description && (
              <p className="post-content-excerpt">{post.frontmatter.description}</p>
            )}
          
            <div className="post-metadata">
              <p><FontAwesomeIcon icon={faClock} /> {post.frontmatter.date}</p>
              <p><FontAwesomeIcon icon={faNewspaper} /> {post.fields.readingTime.text}</p>

              {/* <p>
                <FontAwesomeIcon icon={faComments} />&nbsp;
                <CommentCount config={disqusConfig} placeholder={'0 Comments'} />
              </p> */}

              {(post.frontmatter.yearsAgo >= 3) && (
                <p><FontAwesomeIcon icon={faExclamationTriangle} /> Published over {post.frontmatter.yearsAgo} years ago</p>
              )}
            </div>
          </header>

          <div className="post-content-body" dangerouslySetInnerHTML={{ __html: post.html }} />

          <hr />

          <footer className="post-content-footer">
            {this.props.pageContext.previous && 
              <div>
                <h2>Previously...</h2>
                <div className="post-feed">
                  <PostCard key={this.props.pageContext.previous.fields.slug} node={this.props.pageContext.previous} postClass={`post`} />
                </div>
              </div>
            }

            {/* <h2>Join the conversation</h2>
            <Disqus config={disqusConfig} /> */}
          </footer>
        </article>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        year: date(formatString: "YYYY")
        yearsAgo: date(difference: "years")
        description
      }
      fields {
        readingTime {
          text
        }
      }
    }
  }
`
